<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-descriptions size="large" border :column="2">
      <el-descriptions-item>
        <template #label> Data de início: </template>
        <el-date-picker
          v-model="filters.start_at"
          type="date"
          size="medium"
          format="DD/MM/YYYY"
        >
        </el-date-picker>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Data final: </template>
        <el-date-picker
          v-model="filters.end_at"
          type="date"
          size="medium"
          format="DD/MM/YYYY"
        >
        </el-date-picker>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Categoria de Produto:</template>
        <el-select
          placeholder="Busque pelo nome, CPF ou CNPJ"
          v-model="selectedProductCategory"
          value-key="uid"
          size="medium"
        >
          <el-option
            v-for="item in Categories"
            :key="item.uid"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <el-button @click="() => fetchData()" type="primary">Filtrar</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions size="large" border :column="4">
      <el-descriptions-item>
        <template #label>Preço médio de compra do Kg.</template>
        {{ currencyFormatter.format(Number(this.AnimalsPriceAvgPerKg)) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Peso total dos animais</template>
        {{
          weightFormatter.format(
            Number(this.AnimalAvgWeight * this.TotalAnimals)
          ) + " kg."
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Peso médio do animal</template>
        {{ weightFormatter.format(Number(this.AnimalAvgWeight)) + " kg." }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-card shadow="always">
          <template #header>
            <el-row type="flex" justify="start">
              <el-col>
                <h4 class="mb-0">
                  Peso resumido de entrada e saída de produtos
                </h4>
                <h6 class="text-muted">
                  Mostra vendas para os produtos ascendentes e as compras
                </h6>
              </el-col>
              <el-col :md="16" class="mt-2"> </el-col>
            </el-row>
          </template>
          <VueApexCharts
            type="bar"
            v-if="ProductSellSeries?.length"
            :options="getBarOptions()"
            :series="ProductSellSeries"
          ></VueApexCharts>
        </el-card>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-card shadow="always">
          <template #header>
            <el-row type="flex" justify="start">
              <el-col :md="16">
                <h4 class="mb-0">Vendas de animais</h4>
                <h6 class="text-muted">
                  Mostra quantidade de animais por cada produto vendido
                </h6>
              </el-col>
              <el-col :md="8" class="mt-2">
                <el-row type="flex" justify="end">
                  <el-radio-group v-model="groupType" size="small">
                    <el-radio-button label="Não agrupar" />
                    <el-radio-button label="Agrupar nos produtos ascendentes" />
                  </el-radio-group>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <VueApexCharts
            type="area"
            v-if="ProductSellWithWeightPercentageSeries?.length"
            :options="getAreaOptions()"
            :series="ProductSellWithWeightPercentageSeries"
          ></VueApexCharts>
        </el-card>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-card shadow="always">
          <template #header>
            <el-row type="flex" justify="start">
              <el-col>
                <h4 class="mb-0">Participação nas vendas</h4>
                <h6 class="text-muted">
                  Mostra participação dos produtos ascendentes nas vendas
                </h6>
              </el-col>
              <el-col :md="16" class="mt-2"> </el-col>
            </el-row>
          </template>
          <VueApexCharts
            type="donut"
            v-if="ProductSellDonutSeries?.length"
            :options="getDonutOptions()"
            :series="ProductSellDonutSeries"
          ></VueApexCharts>
        </el-card>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-card shadow="always">
          <template #header>
            <el-row type="flex" justify="start">
              <el-col :md="16">
                <h4 class="mb-0">Preços de compra/venda</h4>
                <h6 class="text-muted">
                  Mostra os preços praticados na compra e venda das peças
                </h6>
              </el-col>
              <el-col :md="8" class="mt-2">
                <el-row type="flex" justify="end">
                  <el-radio-group v-model="groupType" size="small">
                    <el-radio-button label="Não agrupar" />
                    <el-radio-button label="Agrupar nos produtos ascendentes" />
                  </el-radio-group>
                </el-row>
              </el-col>
            </el-row>
          </template>
          <VueApexCharts
            type="area"
            v-if="ProductBuySellSeries?.length"
            :options="getAreaOptions('money')"
            :series="ProductBuySellSeries"
          ></VueApexCharts>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import OrderService from "../services/orders";
import {
  weightFormatter,
  currencyFormatter,
  graphLocales,
} from "../utils/formatters";
import ProductCategoryService from "../services/categories";
import PackingService from "../services/packings";
import ProductEntryService from "../services/entries";
import VueApexCharts from "vue3-apexcharts";
export default {
  name: "OrderResumeReport",
  data: () => ({
    isLoading: false,
    weightFormatter,
    currencyFormatter,
    filters: {
      start_at: new Date(Date.now() - 8.64e7 * 10),
      end_at: new Date(),
    },
    groupType: "Não agrupar",
    orders: null,
    entries: null,
    packings: null,
    categories: null,
    searchWithInvoiceMode: "Apenas com nota",
    selectedProductCategory: null,
  }),
  components: {
    VueApexCharts,
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    Categories() {
      return this.categories || [];
    },
    ProductSells() {
      return this.orders?.map(({ products }) => products).flat();
    },
    ProductSellWithWeightPercentage() {
      return (
        this.orders
          ?.map((order) => order.products)
          ?.flat()
          ?.filter((sell) => !!sell?.product?.weight_percent)
          ?.reduce((products, sell) => {
            const name = !this.shouldGroupSellCalc()
              ? sell.product?.name
              : sell?.product?.ascending?.name || sell?.product?.name;
            if (products[name]) products[name].push(sell);
            else products[name] = [sell];

            return products;
          }, {}) || {}
      );
    },
    ProductSellWithWeightPercentageSeries() {
      return [
        {
          name: "Vendas",
          data:
            Object.entries(this.ProductSellWithWeightPercentage)?.map(
              ([key, entry]) => {
                return {
                  x: key,
                  y: this.sumSellsWeight(entry, true) / this.AnimalAvgWeight,
                };
              }
            ) || [],
        },
        {
          name: "Compras",
          data: Object.keys(this.ProductSellWithWeightPercentage)?.map(
            (sell) => ({
              x: sell,
              y: this.TotalAnimals,
            })
          ),
        },
      ];
    },
    ProductBuySellSeries() {
      return [
        {
          name: "Média de Venda",
          data:
            Object.entries(this.ProductSellWithWeightPercentage)?.map(
              ([key]) => {
                return {
                  x: key,
                  y: this.getAvgOfProductSell(key),
                };
              }
            ) || [],
        },
        {
          name: "Preço Mínimo p/ Venda",
          data: Object.entries(this.ProductSellWithWeightPercentage)?.map(
            ([sellKey, sellProducts]) => ({
              x: sellKey,
              y:
                (this.AnimalsPriceAvgPerKg *
                  Number(sellProducts?.[0]?.product?.min_price)) /
                100,
            })
          ),
        },
      ];
    },
    ProductSellDonutSeries() {
      return this.ProductSellSeries?.[0]?.data?.map(({ y }) => y);
    },
    AnimalsWeightAndPrice() {
      return this.packings
        ?.map(({ animals }) => animals)
        ?.flat()
        ?.map((animal) => {
          const totalWeight = this.sumAnimalWeight(animal.carcasses);
          const totalAmount = totalWeight * Number(animal.price);

          return { totalWeight, totalAmount };
        }) || [];
    },
    AnimalsPriceAvgPerKg() {
      const totalWeight = this.AnimalsWeightAndPrice.reduce(
        (total, { totalWeight }) => total + totalWeight,
        0
      );
      const totalAmount = this.AnimalsWeightAndPrice.reduce(
        (total, { totalAmount }) => total + totalAmount,
        0
      );

      return totalAmount / totalWeight;
    },
    ProductSellSeries() {
      const productSells = this.groupSellsByProducts();
      const productEntries = this.groupEntryByProducts();
      const totalCarcassesWeight = this.TotalCarcassesWeight;

      if (productSells)
        return [
          {
            name: "Vendas",
            data: Object.entries(productSells).map((sells) => {
              const sumWeight = this.sumSellsWeight(sells[1]);

              return {
                x: sells[0],
                y: sumWeight,
              };
            }),
          },
          {
            name: "Entrada Produtos",
            data: Object.entries(productSells || {}).map(([key]) => {
              const sumWeight = this.sumSellsWeight(productEntries[key]);

              return {
                x: key,
                y: sumWeight,
              };
            }),
          },
          {
            name: "Entrada Romaneios",
            data: Object.entries(productSells).map((sells) => {
              const weightPercentage =
                (Number(
                  sells[1]?.[0]?.product?.ascending?.weight_percent ||
                    sells[1]?.[0]?.product?.weight_percent
                ) || 0) / 100;

              return {
                x: sells[0],
                y: weightPercentage * totalCarcassesWeight,
              };
            }),
          },
        ];
      return [{ data: [] }];
    },
    TotalAnimals() {
      return this.packings?.map(({ animals }) => animals)?.flat()?.length || 0;
    },
    AnimalAvgWeight() {
      return this.TotalCarcassesWeight / this.TotalAnimals;
    },
    TotalCarcassesWeight() {
      return (
        this.packings
          ?.map(({ animals }) => animals)
          ?.flat()
          ?.map(({ carcasses }) => carcasses)
          ?.flat()
          ?.reduce((total, carcass) => total + Number(carcass?.weight), 0) || 0
      );
    },
  },
  watch: {
    filters() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    shouldGroupSellCalc() {
      return this.groupType !== "Não agrupar";
    },
    getAvgOfProductSell(key) {
      const products = this.ProductSells?.filter(
        (sell) => sell.product.name === key
      );

      const totals = products?.reduce((total, product) => {
        if (!total.totalWeight) total.totalWeight = 0;
        total.totalWeight += Number(product.weight);

        if (!total.totalAmount) total.totalAmount = 0;

        total.totalAmount += Number(product.total_amount);
        return total;
      }, {});

      return totals.totalAmount / totals.totalWeight;
    },
    sumSellsWeight(sells, includePercentage = false) {
      return (
        sells?.reduce((total, { weight, product }) => {
          const ascendingPercentage =
            Number(product.ascending?.weight_percent || 100) / 100;
          const productPercentage =
            Number(product?.weight_percent || 100) / 100;
          const weightPercentage = this.shouldGroupSellCalc()
            ? product.ascending?.weight_percent
              ? ascendingPercentage
              : productPercentage
            : productPercentage * ascendingPercentage;

          if (includePercentage)
            total += (1 / weightPercentage) * Number(weight);
          else total += Number(weight);

          return total;
        }, 0) || 0
      );
    },
    calcAnimalsByPercentage(weight, percentage = false) {
      return Math.round(
        ((1 / (Number(percentage) / 100)) * Number(weight)) /
          this.AnimalAvgWeight
      );
    },
    sumAnimalWeight(carcasses) {
      return (
        carcasses?.reduce(
          (total, carcass) => total + Number(carcass.weight),
          0
        ) || 0
      );
    },
    async fetchData() {
      await this.fetchPackings();
      this.fetchCategories();
      this.fetchOrders();
    },
    async fetchCategories() {
      const { categories } = await ProductCategoryService().index();

      if (categories) {
        this.categories = categories;
        this.selectedProductCategory = categories[0];
        this.fetchEntries();
      }
    },
    async fetchPackings() {
      const { packings } = await PackingService().index({
        created_at_start: this.filters?.start_at,
        created_at_end: this.filters?.end_at,
      });

      if (packings) this.packings = packings;
    },
    async fetchEntries() {
      const { entries } = await ProductEntryService().index({
        created_at_start: this.filters?.start_at,
        created_at_end: this.filters?.end_at,
        category_id: this.selectedProductCategory?.uid,
        include_ascending: true,
      });

      if (entries) this.entries = entries;
    },
    groupSellsByProducts() {
      return (
        this.orders
          ?.map(({ products }) => products)
          ?.flat()
          ?.filter(
            ({ product }) =>
              product.category?.uid === this.selectedProductCategory?.uid
          )
          ?.filter(
            (sell) =>
              sell.product?.descending?.length || sell.product?.ascending
          )
          ?.reduce((group, sell) => {
            const name = sell?.product?.ascending?.name || sell.product?.name;

            if (name in group) group[name].push(sell);
            else group[name] = [sell];
            return group;
          }, {}) || {}
      );
    },
    groupEntryByProducts() {
      return (
        this.entries?.reduce((products, entry) => {
          const name = entry?.product?.ascending?.name || entry.product.name;
          if (products[name]) products[name].push(entry);
          else products[name] = [entry];

          return products;
        }, {}) || {}
      );
    },
    getBarOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          height: 500,
          type: "bar",
          zoom: {
            enabled: false,
          },
          locales: graphLocales(),
          defaultLocale: "pt-br",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        labels: Object.keys(this.groupSellsByProducts()),
        series: this.ProductSellSeries,
        legend: {
          formatter: (label) => label,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        xaxis: {
          type: "numeric",
        },
        yaxis: {
          labels: {
            formatter: (v) => weightFormatter.format(Number(v) || 0) + " kg.",
          },
        },
      };
    },
    getAreaOptions(labelFormatter) {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          height: 500,
          type: "area",
          zoom: {
            enabled: false,
          },
          locales: graphLocales(),
          defaultLocale: "pt-br",
        },
        colors: ["#e57373", "#b2ebf2"],
        dataLabels: {
          enabled: false,
        },

        legend: {
          formatter: (label) => label,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        yaxis: {
          type: "numeric",
          labels: {
            formatter: (label) =>
              labelFormatter === "money"
                ? this.currencyFormatter.format(Number(label))
                : `${Number(label).toFixed(0)} animais`,
          },
        },
      };
    },
    getDonutOptions() {
      return {
        chart: {
          height: 500,
          type: "donut",
          zoom: {
            enabled: false,
          },
          locales: graphLocales(),
          defaultLocale: "pt-br",
        },
        dataLabels: {
          enabled: true,
        },
        labels: Object.keys(this.groupSellsByProducts()),
        series: this.ProductSellDonutSeries,
        legend: {
          formatter: (label) => label,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        yaxis: {
          labels: {
            formatter: (v) => weightFormatter.format(Number(v) || 0) + " kg.",
          },
        },
      };
    },
    async fetchOrders() {
      this.isLoading = true;

      const filters = {
        include_product_category: true,
        status: "finalized,completed,paid,delivered",
        delivered_at_start: this.filters?.start_at,
        delivered_at_end: this.filters?.end_at,
      };

      const { orders } = await OrderService().index(filters);

      if (orders) {
        this.orders = orders;
      } else this.orders = null;

      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
